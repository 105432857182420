import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  InputAdornment,
  TextField,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Modal,
  Box,
  Typography 
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import fondo from '../../img/contacto/logo.png';
import '../../style/contacto/contacto.css';
import { useCarritoContexto } from '../context/cotizar-final'; 


export default function Contactoformulario() { 
  const backendURL = process.env.REACT_APP_BACKEND_URL; 
  const [loading, setLoading] = useState(false); 
  const [responseStatus, setResponseStatus] = useState(null);
  const [selectedOptionsText, setSelectedOptionsText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [racksData, setRacksData] = useState([]);
  const [productos, setProductos] = useState([]);
  const [message, setMessage] = useState('');
   const {productosEnCotizar,  eliminarDelCarrito } = useCarritoContexto();  
  const [lastMessage, setLastMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    empresa: "",
    region:"",
    ciudad:"",
    correo: "",
    telefono: "",
    mensajesespecifico:"",
    mensajes: "",
  });


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 600px)': {
      width: '70%',
    },
  };


  const handleClose = () => {
    // Cierra el modal o popup
    setResponseStatus(null);
  };
  const handleChanges = (event) => {
    const { name, value } = event.target;
  
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true); // Establecer loading en true al comenzar el envío del correo

      const response = await fetch(`${backendURL}/api/correo-contacto`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const status = response.status;
      setResponseStatus(status);


        // Restablecer los valores del formulario después de un envío exitoso
        setFormData({
          nombre: '',
          empresa: '',
          region: '',
          ciudad: '',
          correo: '',
          telefono: '',
          mensajesespecifico: '',
          mensajes: '',
        });
        setSelectedOptions([]); // Limpia las opciones seleccionadas
        setSelectedOptionsText('');
        setOpenModal(true);
     
    } catch (error) {
      console.error('Error al enviar el correo electrónico:', error);
       // Restablecer los valores del formulario después de un envío exitoso
       setFormData({
        nombre: '',
        empresa: '',
        region: '',
        ciudad: '',
        correo: '',
        telefono: '',
        mensajesespecifico: '',
        mensajes: '',
      });
      setSelectedOptions([]); // Limpia las opciones seleccionadas
        setSelectedOptionsText('');
    } finally {
      setLoading(false); 
      setTimeout(() => {
        handleClose();
      }, 100);// Establecer loading en false después de que se complete el envío del correo
    }
  };


  const handleCloseModal = () => {
    setOpenModal(false);
  };
     
   const generateSelectedOptionsInfo = () => {
    return selectedOptions.map((value) => {
      const productDetails = getNameById(value);
      const productInfo = productosEnCotizar.find((producto) => producto.id === value);
      return `${productDetails} - Cantidad: ${productInfo ? productInfo.cantidad : 1}`;
    });
  };  

 
  const [selectedOptionsInfo, setSelectedOptionsInfo] = useState(generateSelectedOptionsInfo);

  useEffect(() => {
    setSelectedOptionsInfo(generateSelectedOptionsInfo);
  }, [selectedOptions, productosEnCotizar]);
 

  useEffect(() => {
    // Verificar si el mensaje ha cambiado antes de actualizar lastMessage
    if (message !== lastMessage) {
      setLastMessage(message);
    }
  }, [message, lastMessage]); 

   const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };
   
 const handleDelete = (productId) => {
     eliminarDelCarrito(productId);  
  };
   
  useEffect(() => {
    const newSelectedOptionsText = generateSelectedOptionsInfo().join('\n');
    setSelectedOptionsText(newSelectedOptionsText);
    setFormData((prevData) => ({ ...prevData, mensajesespecifico: newSelectedOptionsText }));
  }, [selectedOptions, productosEnCotizar]);
  

  useEffect(() => {
    // Actualizar selectedOptions después de que el carrito sea actualizado
    setSelectedOptions(productosEnCotizar.map((producto) => producto.id));
  }, [productosEnCotizar]);
  

  const getNameById = (id) => {
   
    const selectedProduct = [...productos, ...racksData].find((item) => item.id === id);
  
  
    if (!selectedProduct) return '';
  
    const details = [
      ` ${selectedProduct.nombre}`,
      selectedProduct.capacidad ? `Capacidad: ${selectedProduct.capacidad}` : '',
      selectedProduct.altura ? `Altura: ${selectedProduct.altura}` : '',
      selectedProduct.bateria ? `Batería: ${selectedProduct.bateria}` : '',
      selectedProduct.capacidad_bateria ? `Capacidad de Batería: ${selectedProduct.capacidad_bateria}` : '',
    ];
  
    // Agregar lógica para distinguir entre productos y racks
    if (selectedProduct.tipo === 'producto') {
      details.push(` Maquina`);
    } else if (selectedProduct.tipo === 'rack') {
      details.push(` Rack`);
    }
  
  
  
    return details.filter((detail) => detail !== '').join(', ');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProductos = await axios.get(`${backendURL}/api/get/all/productos`);
        const responseRacks = await axios.get(`${backendURL}/api/get/racksproductos`);

        if (responseProductos.status === 200 && responseRacks.status === 200) {
          const productosData = responseProductos.data.map((producto) => ({ ...producto, tipo: 'producto' }));
          const racksData = responseRacks.data.map((rack) => ({ ...rack, tipo: 'rack' }));
          setProductos(productosData);
          setRacksData(racksData);

          // Actualiza los productos seleccionados por defecto al cargar el componente
           const defaultSelectedOptions = productosEnCotizar.map((producto) => producto.id);
           setSelectedOptions(defaultSelectedOptions);  
        } else {
          console.error("Error al obtener datos");
        }
      } catch (error) {
        console.error("Error de red", error);
      }
    };

    fetchData();
  }, [ productosEnCotizar ]);
  return (
    <>
      <div className="contiene-formulario-requerimiento">
        <section className="titulo-formulario-requerimiento">
          <div className="titulo-informacion-requerimiento">
            <h1 className="titulo-requerimiento">Cotiza con nosotros </h1>
            <h2 className="descripcion-formulario"> Deja tu solicitud y lleva tus operaciones al siguiente nivel.</h2>
          </div>
          <div className="contendor-logo-formulario">
            <img src={fondo} alt="stacker" title="stcker" className="logo-formulario"  />
          </div>
        </section>
        <section className='formulario-requerimiento'>
          <form  onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className='contenedor'>
                <TextField className="input-formulario"  name="nombre"  label="Nombre" variant="outlined" fullWidth value={formData.nombre}
                onChange={handleChanges}/>
              </Grid>
              <Grid item xs={12} md={6} className='contenedor'>
                <TextField className="input-formulario" label="Empresa" name="empresa" variant="outlined" fullWidth value={formData.empresa}
                onChange={handleChanges} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className='contenedor'>
                <TextField className="input-formulario" label="Region" variant="outlined" fullWidth  name='region' value={formData.region}
                onChange={handleChanges}/>
              </Grid>
              <Grid item xs={12} md={6} className='contenedor'>
                <TextField className="input-formulario" label="Ciudad" variant="outlined" fullWidth  name='ciudad' value={formData.ciudad}
                onChange={handleChanges}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} className='contenedor'>
                <TextField className="input-formulario" label="Correo Electrónico" variant="outlined" type="email" fullWidth name='correo' value={formData.correo}
                onChange={handleChanges}/>
              </Grid>
              <Grid item xs={12} md={4} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Teléfono"
                  variant="outlined"
                  type="tel"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+569</InputAdornment>,
                  }}
                  fullWidth
                  name='telefono' value={formData.telefono}
                onChange={handleChanges}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className='contenedor'>
                <FormControl fullWidth>
                  <InputLabel
                    className='label-selecion'
                    id="select-equipos-label"
                    shrink={!!selectedOptions.length}
                  >
                    Selecciona equipo o maquinaria
                  </InputLabel>
                  <Select
                     labelId="select-equipos-label"
                     id="select-equipos"
                     className="input-formulario"
                     IconComponent={KeyboardArrowDownIcon}
                     name='servicios'
                     value={selectedOptions}
                     onChange={handleChange}
                     multiple
                     sx={{
                      whiteSpace:"pre-wrap"
                     }}
                     
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip
                            style={{ background: "#115c67c6", color: "#fff" }}
                            key={value}
                            label={getNameById(value)}
                            onDelete={() => handleDelete(value)} 
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 6 * 48 + 8, // 6 items * item height + some padding
                        },
                      },
                    }}
                  >
                    {[...new Set([...productos, ...racksData])].map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nombre}
                        {item.capacidad ? `- Capacidad: ${item.capacidad}` : ''}
                        {item.altura ? `, Altura: ${item.altura}` : ''}
                        {item.bateria ? `, Batería: ${item.bateria}` : ''}
                        {item.capacidad_bateria ? `, Capacidad de Batería: ${item.capacidad_bateria}` : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className='contenedor'>
              <TextField
        className="input-formulario"
        label="Equipos Seleccionados"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        name='mensajesespecifico'
        value={selectedOptionsText}
        onChange={handleChange}
        disabled 
        readOnly
      />

<TextField
  style={{ marginTop: "20px" }}
  className="input-formulario"
  label="Mensaje"
  variant="outlined"
  name="mensajes"
  rows={4}
  fullWidth
  value={formData.mensajes}
  onChange={handleChanges}
/>
   
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} className='button-contenedor'>
                  <Button  type="submit" className='buton-enviar'> Enviar</Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </section>
      </div>
      <Dialog open={loading || responseStatus !== null} onClose={handleClose}>
          <DialogContent>
            {loading && <CircularProgress/>} 
          </DialogContent>
        </Dialog>
        <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          ¡Mensaje Enviado!
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Su mensaje ha sido enviado correctamente. Nos pondremos en contacto.
        </Typography>
        <Button onClick={handleCloseModal} variant="contained"  sx={{ mt: 3, background:"#115c67", color:"#fff" }}>
          Cerrar
        </Button>
      </Box>
    </Modal>
    </>
  );
}